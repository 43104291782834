header {
    .logo {
        display: block;
        margin: 2rem;
        position: absolute;
        z-index: 2;
    }

    section {
        width: 100%;
        position: relative;
        background-size: cover;

        .image-wrapper {

            &__title {
                height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        h1 {
            position: relative;
            color: $white;
            font-size: 2.4rem;
            margin: 2rem 0 0 0;
            z-index: 2;
            line-height: 50px;

            @media (min-width:782px) {
                font-size: 3.5rem;
            }

            &:last-of-type {
                font-size: 1.75rem;
                display: inline-block;
                margin-top: 0;
                font-weight: unset;
                text-transform: unset;
            }
        }

        img {
            max-width: 80vw;
            margin-bottom: -90px;
        }
    }
}
