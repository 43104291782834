footer {
    color: $white;
    font-size: 0.675rem;
    margin: ($grid-gutter-width * 2) 0 0;
    padding: $grid-gutter-width 0;

    p {
        margin-bottom: $grid-gutter-width / 2;
    }

    a,
    a:visited {
        color: $white;

        &:focus,
        &:hover {
            color: darken($white, 20%) !important;
        }
    }

    img {
        margin-right: 1rem;
    }

    nav {
        margin-top: 1rem;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        a {
            margin-right: 1rem;
            cursor: pointer !important;
            text-decoration: underline !important;
            white-space: nowrap;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
