.cookies {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: $grid-gutter-width;
    background-color: $light-gray;
    font-size: 0.875rem;
    z-index: 9;

    a:focus,
    a:hover {
        color: $black;
    }

    button {
        font-size: 0.625rem;
        margin-right: $grid-gutter-width;
    }

    .btn-link {
        color: $black;

        &:focus,
        &:hover {
            color: lighten($dark, 10%);
        }
    }

    .btn-dark:focus,
    .btn-dark:hover {
        background-color: lighten($dark, 10%);
    }
}
