.titlePage {
    color: $white;
    text-align: center;
    word-break: break-word;

    h1 {
        font-size: 1.8rem;
    }

    @include media-breakpoint-up(md) {
        h1 {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 2.225rem;
        }
    }
}

.contentPage {
    background-color: $white;
    padding: ($grid-gutter-width / 2) 0;

    @include media-breakpoint-up(md) {
        padding: $grid-gutter-width;
    }

    @include media-breakpoint-down(md) {
        h2 {
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
        }
    }
}
