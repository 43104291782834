* {
  box-sizing: border-box;
}

html,
body,
.App {
  background-color: $background;
  color: $text;
  overflow-x: hidden;
}

.csr-background {
  @extend %csr-background;

  &:before {
    opacity: 0.03;
  }

  &__content {
    @extend %csr-background__content;
  }
}
