.container__form {
  p {
    margin-bottom: 1.5rem;
  }

  .alert {
    margin-top: 1rem;
  }
}

.form-group {
  margin-bottom: 1rem;

  input,
  select,
  textarea {
    width: 100%;
    border: none;
    background: transparent;
    padding: ($grid-gutter-width / 2) ($grid-gutter-width / 3);
    color: $text;
    font-size: 0.875rem;

    &::placeholder {
      color: lighten($text, 5%);
      font-size: 0.875rem;
    }

    &:disabled {
      background: lighten($gray, 30%);
      color: darken($gray, 20%);
    }
  }

  button {
    margin-top: 1rem;
    background-color: $primary;
    border: none;

    &:focus,
    &:hover {
      background-color: $secondary;
    }
  }
}

.form-check {
  font-size: 0.875rem;
}

input[type="checkbox"] {
  accent-color: $primary;
}

#approved {
  margin-right: 0.5rem;
  margin-top: 5px;
}

form a,
form a:visited {
    color: $white;
}

form a:focus,
form a:hover {
    color: darken($white, 20%);
}

