// Colors
$background: #002C47;
$text: #032B46;
$light-text: #FFFFFF;
$ground: #442621;
$border: #d5b9aa;
$text-shadow:#0c3c61;
$footer: #102b46;
$dgp-orange: #f5821e;

$white: #fff;
$black: #000;
$gray: #8a8a8a;
$light-gray: #f5f5f5;
$primary: #002C47;
$secondary: #00C1DE;
$blue: #2673B2;

$text-hover: $background;
$blue-hover: #2657b2;

$succes: #48A23F;
$succes-background: #C0FFCE;
$danger: #DE0000;

$duotone-orange: rgb(255,95,0);
$duotone-blue: rgba(9,0,46,1);

%csr-background {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("/csr-background.png");
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.4;
      background-size: cover;
      top: 0;
      left: 0;
    }

    &__content {
        position: relative;
        z-index: 1;
    }
}
