.language-switch {
    margin-top: 1rem;
    text-align: right;
    list-style: none;

    &__button {
        color: $white !important;
        text-decoration: none;

        &.active {
            font-weight: bold;
        }
    }
}
