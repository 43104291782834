.button {
    font-size: 0.725rem;
    color: $white;
    background-color: $secondary;
    padding: 0.625rem !important;
    text-decoration: none;
    border-radius: 10px;
    font-weight: bold;
    transition: all .3s ease-in-out;
    border: 2px solid transparent;
    display: inline-block;

    &:hover {
        background-color: $primary;
        outline: none;
        cursor: pointer;
        color: $white;
    }

    &.answer {
        width: 100%;
        background-color: $white;
        color: $text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        text-align: left;

        &:after {
            content: '';
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            border: 1px solid transparent;
        }

        &:not(.answered):focus,
        &:not(.answered):hover {
            background-color: $primary;
            color: $white;
        }
    }

    &.correct-answer {
        border-color: $succes;
        background-color: $succes-background;

        &:after {
            content: url("data:image/svg+xml,%3Csvg fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' height='30' viewBox='0 96 960 960' width='30'%3E%3Cpath d='M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z'/%3E%3C/svg%3E");
            background-color: #BAE0DB;
            border-color: $succes;
        }
    }

    &.active.wrong-answer {
        border-color: $danger;
        background-color: $white;

        &:after {
            content: url("data:image/svg+xml,%3Csvg fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' height='30' viewBox='0 96 960 960' width='30'%3E%3Cpath d='m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z'/%3E%3C/svg%3E");
            background-color: #E0909A;
            border-color: $danger;
        }
    }

    &.correct-answer,
    &.active.wrong-answer {
        color: $text;
    }


    &.next {
        margin-left: 1rem;
    }

    &.hide-button {
        display: none;
    }

    &.button--pagination:hover {
        background-color: $white;
        color: $text;
    }

    &.button--start,
    &.confirmation {
        border-radius: 0;
    }

    &.previous {
        background-color: $white;
        border-color: $secondary;
        color: $secondary;

        &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}

.btn {
    border-radius: 0;
    padding: 0.625rem;
}
